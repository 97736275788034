.button {
  font-family: var(--font-primary);
  font: 2rem;
}

.butonContainer {
  font-family: var(--font-primary);
  font: 2rem;
  text-align: center;
}

.button,
input[type="submit"],
input[type="reset"] {
  text-align: center;

  display: block;
  background: none;
  color: var(--color-tertiary-800);
  letter-spacing: 1.2px;
  border: none;

  padding: 0;
  cursor: pointer;
  outline: inherit;
  border: 1px solid var(--color-tertiary-800);
  padding: 0.35rem 0.7rem;
  font-weight: 500;
  border-radius: 20px;
  transition: all 0.2s ease-in;
  white-space: nowrap;
  margin: 2rem auto 0 auto;
  font-size: 12px;
}

.buttonBlank {
  display: block;
  position: absolute;
  background: none;
  color: var(--color-tertiary-800);
  letter-spacing: 1.2px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  border: 1px solid var(--color-tertiary-800);
  padding: 0.35rem 0.67rem;
  font-weight: 600;
  border-radius: 20px;
  transition: all 0.2s ease-in;
  white-space: nowrap;
  margin-top: 2rem;
  font-size: 12px;
  text-align: center;
}

.button:hover,
.button:active {
  transform: translateY(-2px);
  transition: all 0.2s ease-in;
  box-shadow: 2px 2px 1px var(--color-primary-box-shadow);
  text-shadow: 10px 10px 25px var(--color-primary-text-shadow),
    -10px 10px 25px #469d701f, -10px -10px 25px #367e5918,
    10px -10px 25px #469d701a;
  cursor: pointer;
}

@media (min-width: 1000px) {
  .button,
  input[type="submit"],
  input[type="reset"] {
    border: 1px solid var(--color-primary-800);
    color: var(--color-primary-800);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: auto;
    padding: 0.5rem 1.2rem 0.58rem 1.2rem;

    font-size: 0.9rem;
    font-weight: 500;

    text-align: center;
  }
  .buttonBlank {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    margin: auto;
  }
}
