@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zen+Tokyo+Zoo&display=swap");

* {
  box-sizing: border-box;
}

html,
body {
  overflow: hidden;
  position: relative;
  height: 100%;
}

body {
  display: flex;
  margin: 0;
}

:root {
  /* fonts */
  --font-zen-maru-gothic: "Zen Maru Gothic";
  --font-family-primary: "Zen Maru Gothic";
  --font-zen-tokyo-zoo: "Zen Tokyo Zoo";
  --font-primary: "Zen Maru Gothic";
  --font-family-secondary: "Zen Tokyo Zoo";
  --font-inherit: inherit;

  /* font sizes */
  --font-size-3xl: 22px;
  --font-size-81xl: 100px;
  --font-size-xl: 20px;
  --font-size-6xl: 25px;
  --font-size-5xl: 24px;
  --font-size-31xl: 50px;

  /* Colors */
  --color-white: #fff;
  --color-white-opaque: #ffffff9a;
  --color-black: #000;
  --color-succes: rgba(121, 207, 101, 0.726);

  --color-fail: rgba(212, 113, 113, 0.792);
  --color-pending: rgba(217, 217, 82, 0.783);
  --color-grey: rgb(96, 96, 63);
  --color-grey-light: rgba(96, 96, 63, 0.54);

  --color-primary-opaque-3: #c19400;

  /* Colors */
  /* --color-primary: rgb(245, 206, 78);
  --color-primary-2: rgb(241, 217, 141);
  --color-primary-3: rgb(191, 148, 6);
  --color-primary-100: rgb(245, 238, 215);
  --color-primary-200: rgb(238, 220, 160);
  --color-primary-300: rgb(240, 227, 185);
  --color-primary-400: rgb(245, 219, 133);
  --color-primary-700: rgb(127, 196, 0);
  --color-primary-800: rgb(255, 252, 241);
  --color-primary-900: rgb(107, 82, 0); */
  --color-primary-light: rgba(245, 206, 78, 0.539);
  --color-primary-light-1s: rgba(114, 96, 36, 0.437);
  --color-primary-light-2: rgba(245, 214, 112, 0.073);
  --color-primary-light-3: rgb(204, 153, 0);
  --color-primary-light-4: rgb(254, 249, 234);
  --color-primary-light-5: rgb(254, 255, 226);
  --color-primary-dark: rgba(158, 122, 1);
  --color-primary-dark-1: rgba(54, 52, 44, 0.727);
  --color-primary-dark-2: rgba(78, 60, 2, 0.727);
  --color-primary-opaque: rgb(255, 244, 205);
  --color-primary-opaque-2: rgb(253, 232, 157);
  --color-primary-opaque-3: rgba(189, 183, 164, 0.159);
  /* Paddings */
  --padding-8xl: 27px;

  /* Border radiuses */
  --br-11xs: 2px;

  --color-primary-100: rgba(173, 247, 182, 0.1);
  --color-primary-200: rgba(173, 247, 182, 0.2);
  --color-primary-300: rgba(173, 247, 182, 0.3);
  --color-primary-400: rgba(173, 247, 182, 0.4);
  --color-primary-500: rgba(173, 247, 182, 0.5);
  --color-primary-600: rgba(173, 247, 182, 0.6);
  --color-primary-700: rgba(173, 247, 182, 0.7);
  --color-primary-800: rgba(173, 247, 182, 0.8);
  --color-primary-900: rgba(173, 247, 182, 0.9);
  --color-primary-1000: rgba(173, 247, 182);
  --color-secondary-100: rgba(28, 55, 56, 0.1);
  --color-secondary-200: rgba(28, 55, 56, 0.2);
  --color-secondary-300: rgba(28, 55, 56, 0.3);
  --color-secondary-400: rgba(28, 55, 56, 0.4);
  --color-secondary-500: rgba(28, 55, 56, 0.5);
  --color-secondary-1000: rgba(28, 55, 56);
  --color-tertiary-100: rgba(77, 72, 71, 0.1);
  --color-tertiary-200: rgba(77, 72, 71, 0.2);
  --color-tertiary-300: rgba(77, 72, 71, 0.3);
  --color-tertiary-400: rgba(77, 72, 71, 0.4);
  --color-tertiary-500: rgba(77, 72, 71, 0.5);
  --color-tertiary-600: rgba(77, 72, 71, 0.5);
  --color-tertiary-700: rgba(77, 72, 71, 0.5);
  --color-tertiary-800: rgba(77, 72, 71, 0.8);
  --color-tertiary-1000: rgba(77, 72, 71);
  --color-quarciary-100: rgba(224, 216, 222, 0.1);
  --color-quarciary-1000: rgba(224, 216, 222);
  --color-quadiary-100: rgba(33, 250, 144, 0.1);
  --color-quadiary-1000: rgba(33, 250, 144);
  --color-sextiary-100: rgba(249, 190, 5, 0.1);
  --color-sextiary-200: rgba(249, 190, 5, 0.2);
  --color-sextiary-500: rgba(249, 190, 5, 0.5);
  --color-sextiary-600: rgba(249, 190, 5, 0.6);
  --color-sextiary-700: rgba(249, 190, 5, 0.7);
  --color-sextiary-800: rgba(249, 190, 5, 0.8);
  --color-sextiary-1000: rgba(249, 190, 5, 1);
}
