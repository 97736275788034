.footerContainer {
  display: flex;
  width: 100%;
  height: 30vh;
  align-items: flex-end;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: -1;
}

.footer {
  font-family: var(--font-primary);
  letter-spacing: 1.2px;
  color: var(--color-primary-dark);
  width: 90%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

@media screen and (max-width: 525px) {
  .footer {
    font-size: 0.8;
    width: 100vw;
    justify-content: center;
  }
  .footerContainer {
    margin-bottom: 4rem;
  }
}
