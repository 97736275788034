.subscriptionsPricingCardFooterButton {
  all: unset;
  height: fit-content;
  width: fit-content;
  font-family: var(--font-family-primary);
  display: flex;
  text-decoration: none;
  border: 1px solid var(--color-secondary-400);
  cursor: pointer;
  font-weight: 400;
  padding: 0.55rem 1.25rem;
  text-align: center;
  z-index: 2;
  letter-spacing: 1.5px;
  border-radius: 2px;
  transition: all ease-in-out 0.3s;
  text-transform: uppercase;
  color: var(--color-tertiary-800);
  z-index: 10000;
}

.subscriptionsPricingCardFooterButtonDisabled {
  pointer-events: none;
}

.subscriptionsPricingCardFooterButton:hover {
  text-shadow: 1px 2px var(--color-sextiary-400);
  box-shadow: 1px 2px var(--color-sextiary-800);
  transform: translateY(-2px);
}

.subscriptionsPricingCardBodySessionIcon {
  width: 20px;
  margin-right: 0.5rem;
}

.subscriptionsPricingCardHeaderContainer {
  font-family: var(--font-family-primary);
  margin-bottom: 1rem;
}

.subscriptionsPricingCardHeaderTitle {
  font-family: var(--font-family-secondary);
  color: var(--color-primary-dark);
}
.subscriptionsPricingCardBodyInfoIconContainer {
  font-family: var(--font-family-primary);
}

.subscriptionsPricingCardBodyInfoSpecContainer {
  font-family: var(--font-family-primary);
}

.subscriptionsPricingCardContainer {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  position: relative;
  transform-style: preserve-3d;
  color: var(--color-tertiary-800);
}

.subscriptionsPricingCardFront {
  position: absolute;
}

.subscriptionsPricingCardBack {
  display: flex;
}
.subscriptionsPricingCardBack,
.subscriptionsPricingCardFront {
  display: grid;
  position: absolute;
  height: 100%;
  width: 100%;
  border: 1px solid var(--color-secondary-1000);
  border-radius: 3px;
  box-shadow: 2px 3px var(--color-secondary-100);
  backface-visibility: hidden;
}

.subscriptionsPricingCardBack {
  padding: 1rem 2rem;
  font-size: 0.8rem;
  transform: rotateY(180deg);
}
.subscriptionsPricingCardBack_media {
  transform: rotateY(180deg);
  transform: rotateX(180deg);
}
.subscriptionsPricingCardFooterButton {
  height: min-content;
}

.subscriptionsPricingCardBack {
  font-family: var(--font-family-primary);
}

@media (min-width: 800px) {
  .subscriptionsPricingCardContainer {
    height: 570px;
    min-width: 320px;
    margin: 1rem 2rem;
    display: grid;
    justify-self: center;
  }
  .subscriptionsPricingCardBack,
  .subscriptionsPricingCardFront {
    padding: 2rem 2.25rem;
    justify-content: center;
  }
  .subscriptionsMainContainer {
    margin: 3rem 4rem;
    width: calc(100vw - 8rem);
  }
  .subscriptionsPricingCardsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(22vw, 330px));
    margin: 0 auto;
    grid-template-rows: repeat(auto-fill, 1fr);
    grid-auto-flow: row;
    /* gap: 3rem; */

    grid-auto-rows: minmax(100px, auto);
    justify-content: space-evenly;
  }
  .subscriptionsPricingCardHeaderTitle {
    display: flex;
    justify-content: center;
    font-family: var(--font-family-secondary);
    font-size: 2rem;
    text-align: center;
  }
  .subscriptionsPricingCardHeaderSubject {
    display: flex;
    margin: 1rem 0;
    justify-content: center;
    font-size: 1.2rem;
    text-align: center;
  }
  .subscriptionsPricingCardHeaderHeadline {
    display: flex;
    justify-content: center;
    text-align: center;
    /* margin: 1rem 5% 0 10%; */
    font-size: 0.85rem;
  }
  .subscriptionsPricingCardBodySessionLineBreakBody {
    height: 1px;
    width: 50%;
    margin: 1rem auto;
    background-color: var(--color-quarciary-1000);
  }
  .subscriptionsPricingCardBodySessionLineBreakIconsBottom {
    height: 1px;
    width: 50%;
    margin: 1rem auto;
    background-color: var(--color-quarciary-1000);
  }

  .subscriptionsPricingCardBodySessionIcon {
  }

  .subscriptionsPricingCardHeaderContainer {
    width: 100%;
    font-family: var(--font-family-primary);
  }
  .subscriptionsPricingCardHeader {
    display: grid;
    /* grid-template-rows: repeat(3, 1fr); */
    /* gap: 1rem; */
  }
  .subscriptionsPricingCardBodyInfoIconContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    column-gap: 1vw;
  }
  .subscriptionsPricingCardBodyInfoIconContainerRow {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1rem;
  }
  .subscriptionsPricingCardBodySessionContainer {
    display: flex;
    white-space: nowrap;
  }
  .subscriptionsPricingCardBodySessionText {
    font-size: 0.85rem;
    margin-left: 5px;
  }
  .subscriptionsPricingCardBodyInfoSpecContainer {
    margin: 0 0 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 2rem;
  }

  .subscriptionsPricingCardFooter {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}

@media (max-width: 799px) {
  .subscriptionsPricingCardContainer {
    min-height: 270px;
    min-width: 300px;
  }
  .subscriptionsMainContainer {
    width: 100vw;
  }

  .subscriptionsPricingCardsContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* grid-auto-flow: column; */
    grid-auto-flow: row;
    row-gap: 3rem;
    grid-auto-rows: minmax(100px, auto);
    padding: 1rem 2.5vw;
  }
  .subscriptionsPricingCardFront {
    display: grid;
    position: absolute;
    width: min-content;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 0.5rem;
    column-gap: 1rem;
    width: 95vw;
    border: 1px solid var(--color-secondary-1000);
    box-shadow: 2px 3px var(--color-secondary-100);
    border-radius: 3px;
    padding: 1rem 1rem;
  }

  .subscriptionsPricingCardHeader {
    width: fit-content;
    margin: auto;
    grid-column: 1 / 1;
    grid-row: 1 / 3;
  }
  .subscriptionsPricingCardHeaderTitle {
    font-size: 6vw;
    width: min-content;
    display: flex;
    text-align: center;

    align-items: center;
    /* line-height: 2.2rem; */
  }
  .subscriptionsPricingCardBodyPricingContainer {
    display: flex;
    align-items: center;

    height: 100%;
  }
  .subscriptionsPricingCardHeaderContainer {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    align-self: center;
    align-content: center;
  }
  .subscriptionsPricingCardHeaderSubject {
    font-size: 3.65vw;
    display: flex;
    align-items: center;
  }

  .subscriptionsPricingCardHeaderHeadline {
    font-size: 3.2vw;
    margin-top: 0.2rem;
    padding: 0 2%;
  }

  .subscriptionsPricingCardsContainer {
    flex-direction: column;
    width: 100vw;
    margin: 0 auto;
    align-items: center;
  }
  .subscriptionsPricingCardBodyInfoIconContainer {
    display: grid;
    grid-column: 2 / 4;
    grid-row: 2 /3;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    padding-top: 0.5rem;
    border-top: 1px solid var(--color-quarciary-1000);
  }
  .subscriptionsPricingCardBodyInfoIconContainerRow {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 0.5rem;
  }
  .subscriptionsPricingCardBodySessionIcon {
    width: 4vw;
  }
  .subscriptionsPricingCardBodySessionContainer {
    display: flex;
    white-space: nowrap;
  }
  .subscriptionsPricingCardBodySessionText {
    font-size: 3vw;
    display: flex;
    align-items: center;
  }
  .subscriptionsPricingCardBodySessionSpecText {
    font-size: 2.8vw;
    padding-left: 10px;
    margin-top: 0.2rem;
  }
  .subscriptionsPricingCardBodySessionText {
    display: flex;
    font-size: 2.8vw;
    justify-content: center;
    margin-left: 4px;
  }
  .subscriptionsPricingCardBodySessionLineBreak {
    display: none;
  }
  .subscriptionsPricingCardFooter {
    grid-column: 3 / 3;
    grid-row: 1 /2;
    align-self: center;
  }
  .subscriptionsPricingCardFooterButton {
    display: flex;
    justify-content: center;
    margin: 0.25rem auto 0 auto;
    margin: 5% auto;
    font-size: 2.5vw;
    padding: 0.5rem 0.85rem;
    border-radius: 3px;
  }
}

@media (max-width: 650px) {
  .subscriptionsPricingCardContainer {
    min-height: 240px;
  }
}

@media (max-width: 535px) {
  .subscriptionsPricingCardBack {
    font-size: 0.5rem;
  }
  .subscriptionsPricingCardContainer {
    min-height: 170px;
  }
}
