.resourcesMainContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: center;
  margin: 7rem auto 7rem auto;
  padding: 0 30%;
  white-space: nowrap;
}

.resourcesLinksContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.resourcesInputContainerInner {
  display: flex;
}
.resourcesInputContainer {
  font-family: var(--font-primary);
  color: var(--color-primary-dark-1);
  margin-left: 1rem;
}
.resourcesHeaderField {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}
.resourcesHeaderValue {
  display: flex;
  margin-left: 0.25rem;
  align-items: center;
}

.resourcesHeaderRecreatedField {
  font-size: 0.8rem;
}

.resourcesHeaderValueATag {
  all: unset;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  color: var(--color-primary-dark-3);

  font-weight: 500;
  transition: ease 0.3s;
}

.resourcesHeaderValueATag:hover {
  color: var(--color-primary-dark-2);
}

.resourcesHeader {
  display: flex;
  font-family: var(--font-primary);
  color: var(--color-primary-dark-1);
  margin: 0 auto 1rem auto;
  text-align: center;
  font-size: 2rem;
}

.resourcesInnerContainer {
  width: min-content;
}

.resourcesLink {
  text-decoration: none;
  all: unset;
  z-index: 80;
  cursor: pointer;
  font-family: var(--font-primary);
  padding: 1rem 2rem;
  border: 1px solid var(--color-sextiary-1000);
  border-radius: 5px;
  color: var(--color-primary-dark);
  text-transform: uppercase;
  transition: all ease-in-out 0.3s;
  letter-spacing: 2px;
  margin: 2rem auto 0 auto;
  min-width: 25rem;
}

.resourcesLink:hover {
  transform: translateY(-2px);
  text-shadow: 1px 2px var(--color-primary-opaque);
  box-shadow: 1px 2px var(--color-primary-opaque-2);
}

@media (max-width: 1095px) {
  .resourcesLink {
    min-width: 17rem;
  }
}

@media (max-width: 735px) {
  .resourcesMainContainer {
    padding: 0;
  }
  .resourcesLink {
    min-width: 10rem;
  }
  .resourcesInputContainer {
    margin-left: 0;
  }
  .resourcesInnerContainer {
    margin: 0 auto;
  }
}

@media (max-width: 535px) {
  .resourcesHeader {
    font-size: 1.5rem;
  }
  .resourcesLink {
    min-width: 6rem;
  }
  .resourcesInputContainerInner {
    display: flex;
    flex-direction: column;
  }
  .resourcesHeaderValue {
    margin-left: 0;
  }
}

@media (max-width: 355px) {
  .resourcesHeader {
    font-size: 1.25rem;
  }
}
