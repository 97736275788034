.subscriptionsMainContainer {
  display: flex;
  width: 100%;
  padding-top: 10rem;
  z-index: 10;
}

.subscriptionsHeaderContainer {
  height: min-content;
}

.subscriptionsPricingCardsContainer {
  display: flex;
  justify-content: center;
  margin: 0 2rem;
}

.subscriptionsPricingCardContainer {
  margin: 0 2rem;
  padding: 2rem 3rem;
  border: 1px solid var(--color-primary-2);
  font-family: var(--font-primary);
  color: var(--color-primary-dark);
  z-index: 2;
  background-color: var(--color-primary-light-2);
}

.subscriptionsPricingCardHeader {
  width: 100%;
  text-align: center;
}

.subscriptionsPricingCardHeaderTitle {
  color: var(--color-primary-dark-2);
  font-family: var(--font-secondary);
  text-transform: capitalize;
  font-weight: 500;
  margin-top: 1.25rem;
  font-size: 2rem;
  margin-bottom: 1rem;
  letter-spacing: 1.25px;
}

.subscriptionsPricingCardHeaderSubject {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.subscriptionsPricingCardHeaderHeadline {
  font-size: 0.8rem;
}

.subscriptionsPricingCardBack {
  display: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  visibility: hidden;
}

.subscriptionsPricingCardFooter {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
}

.subscriptionsPricingCardFooterButton {
  display: flex;
  text-decoration: none;
  all: unset;
  border: 1px solid var(--color-primary-2);
  cursor: pointer;
  font-weight: 1000;
  padding: 0.55rem 1.25rem;
  text-align: center;
  z-index: 2;
  letter-spacing: 1.5px;
  border-radius: 2px;
  transition: all ease-in-out 0.3s;
  background-color: var(--color-primary-2);
  text-transform: uppercase;
  color: var(--color-primary-light-3);
}

.subscriptionsPricingCardFooterButton:hover {
  text-shadow: 1px 2px var(--color-primary-2);
  box-shadow: 1px 2px var(--color-primary-light-3);
  transform: translateY(-2px);
  border: 1px solid var(--color-primary);
}

.subscriptionsPricingCardBody {
  margin-bottom: 1rem;
}

.subscriptionsPricingCardBodyInfoIconContainer {
  margin-bottom: 1rem;
}

.subscriptionsPricingCardBodySessionIcon {
  width: 20px;
  margin-right: 0.5rem;
}

.subscriptionsPricingCardBodyInfoIconContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.subscriptionsPricingCardBodySessionContainer {
  display: flex;
  flex: 1;
  justify-content: center;
}

.subscriptionsPricingCardBodySessionText {
  text-align: center;
  white-space: nowrap;
  align-self: center;
}

.subscriptionsPricingCardBodySessionTermStart {
  font-weight: 200;
}

.subscriptionsPricingCardBodySessionPrice {
  margin-top: 1rem;
  font-weight: 200;
}

.subscriptionsPricingCardBodySessionSpecText {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.subscriptionsPricingCardBodySessionLineBreak {
  flex-basis: 100%;
  overflow: hidden;
}

.subscriptionsPricingCardBodyInfoIconContainerRow {
  display: flex;
}

.subscriptionsPricingCardBodySessionLineBreakIcons {
  margin: 0.25rem 0;
  width: 0px;
  height: 15px;
}

.subscriptionsPricingCardBodySessionLineBreakIconsBottom {
  border: 0.25px solid var(--color-primary-light-1);

  margin: 1.5rem 20% 2rem 20%;
}

.subscriptionsPricingCardBodySessionLineBreakBody {
  margin: 1rem 0;
  width: 100%;
  border: 0.25px solid var(--color-primary-light-1);
}
