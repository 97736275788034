.headerSection {
  width: 100%;
  height: 20vh;
}

.headerImg {
  display: flex;
  height: 50px;
}

.headerctaContainer {
  justify-content: end;
  display: flex;
  width: 95%;
  margin-top: -10vh;
}

.headerTrialText {
  height: 20px;
  margin-top: 0.85rem;
  margin-right: 0.8rem;
}

.headerTrialCircle {
  height: 50px;
  position: absolute;
  cursor: pointer;
}

.headerImgContainer {
  display: flex;
  justify-content: center;
  /* position: absolute; */
  width: 100%;
  margin-top: 7.5vh;
  /* height: 100%; */
}

.headerBooking {
  all: unset;
  padding: 1rem 1.5rem;
  font-family: var(--font-primary);
  text-decoration: none;
  border-radius: 2px;
  border: 1px solid var(--color-primary);
  color: var(--color-primary-dark);
  letter-spacing: 2px;
  cursor: pointer;
  z-index: 20;
  transition: all ease-in-out 0.3s;
}

.headerBooking:hover {
  transform: translateY(-2px);
}

.scalesResourcesBack {
  display: flex;
  justify-content: flex-end;
  margin: -5% 0 0 0;
  width: 100%;
}

.scalesResourcesLink {
  all: unset;
  display: flex;
  font-family: var(--font-primary);
  align-items: center;
  justify-content: flex-end;
  text-align: end;
  cursor: pointer;
  z-index: 100;
  width: min-content;
  padding: 0.75rem 1.25rem;
  border-radius: 3px;
  color: var(--color-primary-dark);
  letter-spacing: 1.5px;
  border: 1px solid var(--color-primary-3);
  transition: all ease-in-out 0.3s;
  margin-right: 5%;
}

.scalesResourcesLink:hover {
  transform: translateY(-2px);
  text-shadow: 1px 2px var(--color-primary-opaque);
  box-shadow: 1px 2px var(--color-primary-3);
}

.scalesResourcesLinkArrow {
  display: flex;
  width: 20px;
  text-align: center;
  align-items: center;
  margin-right: 1rem;
}
